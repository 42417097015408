<style scoped>
.forget-pass /deep/.el-form-item__label {
  line-height: 60px;
}

.login-sty-active {
  border: 1px solid #d9d9d9;
  height: 240px;
  padding: 70px 60px;
  display: flex;
  width: 600px !important;
  position: absolute;
  left: -160px;
  top: 37%;
}

.login-sty-active .demo-ruleForm {
  width: 400px !important;
}

.bind-wx {
  position: absolute;
  top: 20px;
  right: 400px;
  font-size: 16px;
  font-weight: bold;
}

.login-sty-active .success-tips {
  width: 160px;
  height: 160px;
  margin-right: 40px;
  background: #f1f1f1;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login-sty-active .success-tips img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.pass-login img {
  cursor: pointer;
  width: 40px;
  margin-top: 15px;
}

.wx-contain {
  width: 368px;
  height: 348px;
  border: 1px solid #d9d9d9;
  margin-left: 40px;
  position: relative;
  margin-top: 16px;

}

.wx-login {
  color: #86b8bc;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.wx-login-h1 {
  margin: 24px auto;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  background-color: #86b8bc;
  border-color: #86b8bc;
}

/deep/.el-input input:-webkit-autofill {
  background: none;
  box-shadow: 0 0 0px 1000px #fff inset !important;
}

/deep/.el-input__inner {
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  height: 44px;
  margin-top: 10px;
}


.pass-ruleForm /deep/.el-input__suffix {
  top: 15px !important;
  right: 10px !important;
  cursor: pointer;
}

.bac-img {
  width: 35%;
  height: 100vh;
  background: #4fa1a4;
  position: relative;
}

.bac-img img {
  width: 68%;
  margin-top: 10vh;
  left: 60px;
  border-radius: 10px;
}

.login-box {
  /* padding-top: 4vh; */
  /* padding-bottom: 4vh; */
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.login-right {
  float: right;
  position: relative;
  width: 460px;
  height: 100%;
  padding-top: 20vh;
  padding-bottom: 4vh;
  box-sizing: border-box;
  margin-right: calc(32.5% - 250px);
}

a {
  color: #4fa1a4;
}

/deep/.el-form-item__error {
  left: 15px;
}

.title-txt {
  font-size: 28px;
  margin-top: 30px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.font-line {
  font-size: 26px !important;
  color: rgb(202, 201, 201);
}

.cen-sty {
  /* width: 1000px; */
  margin: 0 auto;
  overflow: hidden;
}

.logo-sty-img-big {
  width: 250px;
  /* height: 58px; */
}

.logo-sty-img-box {
  width: 250px;
  height: 58px;
  margin-left: 105px;
}

.login-sty {
  width: 400px;
  height: 200px;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 40px;
}

.foot-sty {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  text-align: center;
}

.left-title {
  color: #fff;
  font-size: 22px;
  text-align: center;
  padding: 0 11%;
  font-weight: 500;
  line-height: 40px;
  margin-top: 8vh;
}

.desc-text {
  font-size: 15px;
  color: #fff;
  padding: 0 16% 0 16%;
  text-align: left;
  line-height: 28px;
}

.label-tip {
  text-align: left;
  padding-left: 15px;
  line-height: 13px;
  color: rgb(156, 156, 156);
}

.el-button+.el-button {
  margin-left: 0;
}

/deep/.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 适配火狐 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ 适配火狐 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+  适配ie*/
  color: rgb(156, 156, 156);
}

/deep/.el-dialog {
  margin-left: calc(67.5% - 210px);
  border-radius: 8px;
}

.forget-sty {
  font-size: 12px;
  color: #b2b2b2;
  cursor: pointer;
}

.hqyzm {
  border-left: 1px solid #b2b2b2;
  width: 100px;
  height: 22px;
  font-size: 14px;
  line-height: 18px;
  color: #4fa1a4;
  padding-left: 10px;
  position: absolute;
  z-index: 5;
  right: 5px;
  margin-top: -30px;
  cursor: pointer;
  text-align: center;
}

.eye-k-sty {
  position: absolute;
  z-index: 9;
  right: 10px;
  margin-top: 26px;
  cursor: pointer;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #9d9d9d;
  font-size: 13px;
}

/deep/ .el-checkbox__label {
  color: #9d9d9d;
  font-size: 13px;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #4fa1a4;
  border-color: #4fa1a4;
}

.xieyi {
  font-size: 13px;
  margin: 20px 0;
  text-align: left;
}

.more-contain {
  font-size: 12px;
  color: #B2B2B2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-contain .line {
  height: 1px;
  width: 150px;
  background: #D9D9D9;

}

.more-contain .txt {
  margin: 0 10px;
}

.pass-ruleForm /deep/ .el-form-item {
  margin-bottom: 15px;
}
</style>
<template>
  <div class="cen-sty">
    <div v-if="!isBindWx" v-loading.fullscreen.lock="fullscreenLoading" class="login-box">
      <div class="bac-img fl">
        <!-- <img v-if="logoUrl" :src="logoUrl" alt=""> -->
        <img v-if="loginType == 1" src="../assets/left-pic-1.png" alt="" />
        <img v-if="loginType == 2" src="../assets/left-pic-2.png" alt="" />
        <img v-if="loginType == 3" src="../assets/left-pic-3.png" alt="" />
        <p class="left-title" v-html="leftTitle"></p>
        <div class="desc-text" v-html="desc"></div>
      </div>
      <div class="login-right">
        <div class="logo-sty-img-box">
          <img class="logo-sty-img-big" src="../assets/logo-img-new.png" />
          <!-- <img v-if="titleImgUrl" class="logo-sty-img-big" :src="titleImgUrl" /> -->
        </div>
        <div class="title-txt font-S28">
          <span class="fontC-4fa1a4">{{ logoTitle }}</span> <span class="font-line">|</span>
          <span class="fontC-4fa1a4">登录</span>
          <div style="height: 20px"></div>
        </div>
        <div class="fl wx-contain" v-if="isWxLogin">
          <div class="wx-login-h1">微信扫码登录</div>
          <div id="login_container">

          </div>
          <div class="wx-login" @click="isWxLogin = false">账号登录</div>
        </div>
        <div :class="!wxCode && !isBindWx ? 'login-sty fl' : 'login-sty-active login-sty fl'" v-else>
          <div class="bind-wx" v-if="wxCode">微信绑定账号</div>
          <div class="success-tips" v-if="wxCode">
            <img src="../assets/images/icon_wx_succ.png" />验证成功
          </div>
          <el-form :model="ruleForm" ref="ruleForm" label-width="0" class="demo-ruleForm  pass-ruleForm">
            <el-form-item label="" prop="username">
              <!-- <p class="label-tip" v-if="ruleForm.username !== ''">登录账号</p> -->
              <el-input v-model.trim="ruleForm.username" class="w100f" placeholder="输入账号">
                <i slot="suffix" v-if="ruleForm.username" @click="ruleForm.username = ''; ruleForm.password = ''">
                  <img style="width:15px;" src="../assets/ic_clear.png" alt="" srcset="">
                </i>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="pwd">
              <!-- <p class="label-tip" v-if="ruleForm.pwd !== ''">密码</p> -->
              <el-input :type="passw" v-model.trim="ruleForm.password" class="w100f" placeholder="输入密码">
                <i slot="suffix" v-if="passw == 'password' && ruleForm.password" @click="passw = 'text'">
                  <img style="width:15px;" src="../assets/close_eye.png" alt="" srcset="">
                </i>
                <i slot="suffix" v-if="passw == 'text' && ruleForm.password" @click="passw = 'password'">
                  <img style="width:15px;" src="../assets/open_eye.png" alt="" srcset="">
                </i>
              </el-input>
            </el-form-item>
            <div class="xieyi">
              <el-checkbox v-model="ruleForm.isAgree">我已阅读并同意<span style="color: #4fa1a4"
                  @click.stop.prevent="newOpen(0)">《隐私声明》</span>及<span style="color: #4fa1a4"
                  @click.stop.prevent="newOpen(1)">《用户协议》</span></el-checkbox>
            </div>
            <el-form-item>
              <el-button size="medium" :type="buttonType == 0 ? 'no' : 'info'"
                style="width: 100%; height: 45px; border-radius: 0px" @click="submitForm('ruleForm')">登录</el-button>
              <!-- <div class="forget-sty" v-if="!wxCode&&!isBindWx" @click="forgetPwd">忘记密码</div> -->
            </el-form-item>
            <div v-if="!wxCode && !isBindWx && loginType != 2" class="more-contain">
              <div class="line"></div>
              <div class="txt">更多登录方式</div>
              <div class="line"></div>
            </div>
            <div v-if="!wxCode && !isBindWx && loginType != 2" class="pass-login" @click="openCode">
              <img src="../assets/images/icon_wx.png" alt="" srcset="">
            </div>
          </el-form>
        </div>
        <!-- <div class="login-sty fl">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
              <el-form-item label="" prop="username">
                <p class="label-tip" v-show="ruleForm.username !== ''">登录账号</p>
                <el-input  oninput="value=value.replace(/\s*/g,'')"  v-model.trim="ruleForm.username" class="w100f" placeholder="登录账号">
                  <i slot="suffix" v-if="ruleForm.username" @click="ruleForm.username = ''; ruleForm.password = ''">
                    <img style="width:15px;" src="../assets/ic_clear.png" alt="" srcset="">
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <p class="label-tip" v-show="ruleForm.password !== ''">密码</p>
                <el-input oninput="value=value.replace(/\s*/g,'')"  :type="passw == 'text' ? 'text' : 'password'" v-model.trim="ruleForm.password" autocomplete="off"
                  class="w100f" placeholder="密码">
                  <i slot="suffix" v-if="passw == 'password' && ruleForm.password" @click="passw = 'text'">
                    <img style="width:15px;" src="../assets/close_eye.png" alt="" srcset="">
                  </i>
                  <i slot="suffix" v-if="passw == 'text' && ruleForm.password" @click="passw = 'password'">
                    <img style="width:15px;" src="../assets/open_eye.png" alt="" srcset="">
                  </i>

                </el-input>
              </el-form-item>
            <div class="xieyi"><el-checkbox v-model="ruleForm.isAgree">我已阅读并同意<span style="color:#4fa1a4;"
                  @click.stop.prevent="newOpen(1)">《隐私声明》</span>及<span style="color:#4fa1a4;"
                  @click.stop.prevent="newOpen(0)">《用户协议》</span></el-checkbox></div>
            <el-form-item>
              <el-button size="medium" :type="buttonType == 0 ? 'no' : 'info'"
                style="width: 100%; height: 45px; border-radius: 8px" @click="submitForm('ruleForm')">登录</el-button>
            </el-form-item>

          </el-form>
        </div> -->
        <div class="foot-sty">
          COPYRIGHT © 2016-2024 <a href="https://beian.miit.gov.cn"> 京ICP备2024044205号 </a> 北京传世博润科技有限公司
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sha1 from "sha1";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      fullscreenLoading: false,
      loginType: null,
      unionId: null,
      wxCode: null,
      isBindWx: true,
      isWxLogin: false,
      passw: 'password',
      locationUrl: null,
      currentIndex: -1,
      tenantList: [],
      showLogin: false,
      gridData: [
      ],
      servicesData: [],
      ruleForm: {
        username: '',
        password: '',
        serviceName: '',
        serviceCode: '',
        isAgree: false,
      },
      tableLoading: false,
      titleImgUrl: '',
      logoUrl: '',
      page: {
        pageIndex: 1,
        pageSize: -1,
        totalPage: 0,
        totalRows: 0,
        name: '',
      },
      leftTitle: '',
      logoTitle: '',
      desc: '',
    };
  },
  computed: {
    buttonType() {
      if (this.ruleForm.username !== '' && this.ruleForm.pwd !== '' && this.ruleForm.isAgree) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  created() {
    document.title = '登录';
    this.getData('数字营销支持');
    let descData = JSON.parse(localStorage.descData);
    if (localStorage.descData) {
      this.leftTitle = descData.title;
      this.logoTitle = descData.logoTitle;
      this.desc = descData.content;
      this.loginType = descData.loginType;
      localStorage.loginType = descData.loginType;
    }
  },
  methods: {
    newOpen(type) {
      if (type == 1) {
        window.open(window.location.origin + '/userAgree')
      } else {
        window.open(window.location.origin + '/userPrivate')
      }
    },
    getData(name) {
      let self = this;
      if (name) {
        self.page.name = name;
      } else {
        self.page.name = '';
      }
      self.tableLoading = true;
      let promise = {
        name: self.page.name,
        pageIndex: self.page.pageIndex,
        pageSize: self.page.pageSize,
      };
      self.api.postJson('/ms-user-unified-authentication-service/services/get', promise).then((res) => {
        if (res.data.code == '00000') {
          if (self.page.name !== '') {
            self.titleImgUrl = res.data.data[0].titleImgUrl;
            self.logoUrl = res.data.data[0].logoUrl;
            self.ruleForm.serviceName = res.data.data[0].name;
            self.ruleForm.serviceCode = res.data.data[0].code;
          } else {
            self.servicesData = res.data.data;
          }
          if (this.$route.query.code) {
            this.wxCode = this.$route.query.code;
            this.getUserWeChatInfo();
          } else {
            this.isBindWx = false;
          }
          self.tableLoading = false;
        } else {
          self.$message.error(res.data.msg);
        }
      });
    },
    submitForm(formName) {
      let self = this;
      let appsideguid = this.$route.query.appsideguid;

      if (self.buttonType == 1) {
        self.$refs[formName].validate((valid) => {
          if (valid) {
            let promise = {
              username: self.ruleForm.username,
              password: self.$md5(self.ruleForm.password),
              serviceCode: self.ruleForm.serviceCode,
              appSideGuid: appsideguid,
            };
            self.api.postJson('/ms-user-unified-authentication-service/user/login', promise).then((res) => {
              if (res.data.code == '00000') {
                let url = res.data.data[0].url;
                this.getToken(url);
              } else {
                self.$message.error(res.data.msg);
              }
            });
          }
        });
      }
    },
    getToken(url) {
      let self = this;
      let promise = {
        platformguid: '1e2409b7ef0b412c2bb84e38f8fe50ba',
        appsideguid: 'f2cb03a2b3e84149df939753f9825054',
        token: this.getQueryString(url, 'token') || localStorage.token,
      };
      console.log(promise)
      self.api
        .post(
          '/csbr-zuul/user/unifiedLogin/platform?appsideguid=' +
          promise.appsideguid +
          '&platformguid=' +
          promise.platformguid +
          '&token=' +
          promise.token,
        )
        .then((res) => {
          if (res.data.code == '00000') {
            self.$store.commit('$_setStorage', res.data.data.token);
            self.getCurrentuser();
          } else {
            self.$message.error(res.data.msg);
          }
        });
    },
    getCurrentuser() {
      let self = this;
      self.api.get('/ms-customer-service/tenant/data/get-tenants-by-currentuser').then((res) => {
        if (res.data.code == '00000') {
          localStorage.tenantList = JSON.stringify(res.data.data);
          localStorage.systemEnv = res.data.data[0].systemEnvironment;
          self.api.get(`/ms-common-admin-platform-auth-service/user/get-latest-login-info`).then((resp) => {
            self.getCurrentstaff(resp.data.data.latestLoginTenantGuid || res.data.data[0].guid);
          })

        } else {
          self.$message.error(res.data.msg);
        }
      });
    },
    getQueryString(url, name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20'),
        ) || null
      );
    },
    getCurrentstaff(tenantGuid) {
      let self = this;
      self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then((res) => {
        if (res.data.code == '00000') {
          self.$store.commit('$_setUserId', res.data.data.userGuid);
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
          self.bindWxUnionId(tenantGuid);
        } else {
          self.$message.error(res.data.msg);
        }
      });
    },
    getMenuList(tenantGuid) {
      let self = this;
      self.api
        .get(
          '/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' +
          `appType=01&tenantGuid=` +
          tenantGuid +
          `&appSideIdentifier=QYD`,
        )
        .then((res) => {
          this.fullscreenLoading = false;
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            localStorage.setItem('menuList', JSON.stringify(self.menuList));
            self.$message.success('登录成功');
            setTimeout(function () {
              if (res.data.data[0].menuName == '主页') {
                self.$router.push({
                  path: res.data.data[0].path,
                  query: {
                    username: self.ruleForm.username,
                    password: self.$md5(self.ruleForm.password),
                    currentTenantGuid: tenantGuid,
                    logoTitle: self.logoTitle,
                    token: localStorage.token
                  },
                });
              } else {
                var url;
                var frontEnv = window.env;//前端环境
                var systemEnv = localStorage.systemEnv;//后台环境
                if (frontEnv === 'pro' && systemEnv == 'pro') {
                  //生产1
                  url = `https://scm.csyaoly.com/LoginAccess?tenantGuid=${tenantGuid}&logoTitle=${this.logoTitle}&username=${self.ruleForm.username
                    }&password=${self.$md5(self.ruleForm.password)}&token=${localStorage.token}`;
                } else if (frontEnv === 'pro' && systemEnv == 'pre') {
                  //生产2
                  url = `https://scm.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&logoTitle=${this.logoTitle}&username=${self.ruleForm.username
                    }&password=${self.$md5(self.ruleForm.password)}&token=${localStorage.token}`;
                } else if (frontEnv === 'sit' && systemEnv == 'sit') {
                  //测试1
                  url = `https://scm-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&logoTitle=${this.logoTitle}&username=${self.ruleForm.username
                    }&password=${self.$md5(self.ruleForm.password)}&token=${localStorage.token}`;
                } else if (frontEnv === 'sit' && systemEnv == 'uat') {
                  //测试2
                  url = `https:scm-pre-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&logoTitle=${this.logoTitle}&username=${self.ruleForm.username
                    }&password=${self.$md5(self.ruleForm.password)}&token=${localStorage.token}`;
                } else {
                  //开发
                  url = `http://localhost:8084/LoginAccess?tenantGuid=${tenantGuid}&logoTitle=${this.logoTitle}&username=${self.ruleForm.username
                    }&password=${self.$md5(self.ruleForm.password)}&token=${localStorage.token}`;
                }
                window.location = url;
              }
            }, 100);
          } else {
            self.$message.error(res.data.msg);
          }
        });
    },
    getSort(ruleForm) {
      let arr = [];
      let sum = 0;
      let parame = '';
      for (let r in ruleForm) {
        sum++
        arr.push(r + ruleForm[r]);
      }
      arr = arr.sort();
      if (sum == arr.length) {
        arr.forEach((i) => {
          parame = parame + i;

        })
      }
      // console.log(parame);
      return parame;
    },
    openCode() {
      this.isWxLogin = true;
      this.$nextTick(() => {
        let obj = new WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: "wx4961fdf18ce4f575",
          scope: "snsapi_login",
          state: "STATE",
          style: 'black',
          redirect_uri: encodeURIComponent(location.href),
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE1NXB4OyBtYXJnaW46IDBweDtib3JkZXI6IG5vbmU7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDMwMHB4O30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KLmltcG93ZXJCb3ggLmljb24zOF9tc2cuc3VjY3tkaXNwbGF5Om5vbmU7fQ=="
        });
      })
    },
    //根据code获取token
    getUserWeChatInfo() {
      let self = this;
      let platformGuid = '1e2409b7ef0b412c2bb84e38f8fe50ba';
      let userStatusType = 1;
      let appSideGuid = 'f2cb03a2b3e84149df939753f9825054';
      let serviceCode = this.ruleForm.serviceCode
      let ruleFormNew = {
        code: self.$route.query.code,
        platformGuid: platformGuid,
        appSideGuid: appSideGuid,
        userStatusType: userStatusType
      };
      let par = self.getSort(ruleFormNew);
      let time = Date.parse(new Date());
      let parame = 'FBT@CsBR_943Rsve30fFFBPoi0o0o0' + par + 'FBT@CsBR_943Rsve30fFFBPoi0o0o0';

      let sign = sha1(parame).toUpperCase();
      self.api.postSign(`/csbr-zuul/user/webchat-tenant-login?platformGuid=` + ruleFormNew.platformGuid + `&serviceCode=` + serviceCode + `&code=` + ruleFormNew.code + `&appSideGuid=` + ruleFormNew.appSideGuid + `&userStatusType=1&validateCode=`, { 'sign': sign, 'timeStamp': time / 1000 }).then(res => {
        if (res.data.code == '00000') {
          //绑定成功直接跳转
          this.isBindWx = true;
          self.fullscreenLoading = true;
          localStorage.setItem('wynToken', JSON.stringify('670550ae1460a86607190cd98b36eea4dcf0069a7e9d1f8c3af08b4b6936e6ef'));
          let url = res.data.data[0].url;
          this.getToken(url);
        } else if (res.data.code == 'U0200') {
          //用户未绑定
          this.isBindWx = false;
          this.unionId = res.data.data.unionId;
        } else {
          this.isBindWx = false;
          //异常
          this.$message.error(res.data.msg);
          this.wxCode = null;
        }
      })
    },
    //绑定微信号
    bindWxUnionId(tenantGuid) {
      if (this.unionId) {
        this.api.put('/ms-common-admin-platform-auth-service/user/data/bind-webchat-user?unionId=' + this.unionId,).then(res => {
          if (res.data.code == '00000') {
            this.$message.success('微信绑定成功');
            this.getMenuList(tenantGuid)
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.getMenuList(tenantGuid)
      }
    },
  },
};
</script>
